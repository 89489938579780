import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const CununiaCivilaPage = () => (
  <Layout>
    <SEO title="Cununia Civilă" />
    <div className="drawer article-content">
      <h1 className="article-title">Cununia Civilă</h1>

      <p>
        Când ne gândim la ziua nunții ne gândim la momentul în care păsim spre
        altar, însă cununia religioasă nu poate avea loc dacă nu aveți
        certificatul de cununie civilă.{" "} Vă asigur că și cununia civilă
        reprezintă un moment plin de emoții, mai ales acel “DA”.
      </p>

      {/* <img src="https://images.unsplash.com/photo-1508839370228-5ae14793c2f5?ixlib=rb-1.2.1&auto=format&fit=crop&w=668&q=80" /> */}

      <h3 className="paragh-title">
        La ce trebuie să ne gândim pentru organizare cununiei civile?
      </h3>
      <p>
        În primul rând trebuie sî ne gândim când va avea loc. Cununia civilă în
        aceeasi zi cu cununia religioasă și petrecerea:{" "}
        </p>
        <section>
          Pro:
          <ul>
            <li>
              va fi mai ușor pentru invitați, având în vedere că vor fi oricum
              prezenți pentru petrecere
            </li>
            <li>
              nu vei mai avea nevoie de programări separate pentru coafură și
              machiaj și poți face unul la începutul zilei
            </li>
            <li>nu va trebui un serviciu suplimentar pentru foto și video</li>
            <li>
              nu va mai fi nevoie de încă o mică petrecere și după cununia
              civilă
            </li>
          </ul>
          Contra:
          <ul>
            <li>
              va fi destul de obositor și va fi nevoie de un program bine pus la
              punct
            </li>
            <li>
              vei face ședință foto și la cununia civila, așadar probabil va
              trebui să te trezești destul de devreme
            </li>
            <li>
              va trebui să decizi dacă vei merge la cununia civilă direct în
              rochia de mireasă sau într-o altă rochie
            </li>
          </ul>
        </section>
      

      <h3 className="paragh-title">Cununia civila într-o altă zi</h3>
      <p>
        Dacă alegi fix ziua dinaintea cununie civile poate nu este tocmai o idee
        bună pentru că va fi destul de obositor, va trebui să asiguri cazare
        invitaților pentru încă o zi, vei avea nevoie de machiaj și coafat
        pentru încă o zi etc. Dacă alegi o altă zi:{" "}
        </p>
        <section>
          Pro:
          <ul>
            <li>
              va fi mai puțin stresant și te vei bucura din plin de eveniment
            </li>
            <li>
              vei putea face cununia fix cum îți dorești și vei putea invita
              doar oamenii pe care ți-i dorești lângă tine
            </li>
          </ul>
          Contra:
          <ul>
            <li>
              îți va fi mai greu cu lista de invitați, dacă alegi o zi a
              săptămânii probabil oamenii vor fi nevoiți să își ia o zi liberă
            </li>
            <li>
              va trebui să organizezi o mică petrecere pentru invitații de la
              cununie
            </li>
            <li>
              probabil vor fi costuri suplimentare cu machiaj, coafat, foto și
              video
            </li>
          </ul>
        </section>
      

      <p>
        Indiferent că veți decide să faceți cununia civilă în aceeași zi cu
        restul nunții sau într-o altă zi, găsiți mai jos câteva informații
        necesare pentru organizare: Locația: va trebui să fie la una din
        adresele de domiciliu a unuia dintre soți.{" "}
      </p>
        <section>
          Acte necesare:
          <ul>
            <li>actele de identitate ale celor doi soți (original și copie)</li>
            <li>
              actele de identitate ale martorilor (copie), însă în ziua cununie
              va trebui să le aibă și în original
            </li>
            <li>certificatele de naștere ale soților (original și copie)</li>
            <li>
              certificat prenupțial - un certificat medical care are
              valabilitate de 14 zile
            </li>
            <li>
              formularul tip pentru căsătorie, pe care îl vei primi pentru
              completat când vei merge să depui dosarul
            </li>
            <li>timbre fiscale</li>
            <li>
              chitanta care dovedeste plata taxei de căsătorie (taxa o vei plăti
              în ziua când vei depune dosarul)
            </li>
            <li>dosar cu șină</li>
          </ul>
        </section>
      <p>
        Documentele se depun înainte cu 10-14 zile de data la care doriți să
        organizați cununia civila, incluzând ziua depunerii actelor și ziua în
        care are loc cununia. Pentru depunere trebuie să fie prezenți ambii
        soți. Certificatele prenupțiale au valabilitate de 14 zile. Trebuie să
        verificați înainte și programul insituției în care urmează să depuneți
        actele. Numele pe care îl va purta fiecare din soți îl vei alege atunci
        când completați formularul. Dacă decideți schimbarea numelui va trebui
        să vă schimbați buletinul în maxim 15 zile și ulterior să vă ocupați și
        de actualizarea datelor la job, bănci, clinici medicale și la restul
        serviciilor unde contractele sunt pe numele tău.
      </p>

      <p>
        Durata cununiei civile depinde și de locul unde va avea loc, în
        București cununiile sunt programate pentru 10-15 minute și sunt fix una
        după alta, așadar vei putea face poze doar afară sau poți alege altă
        locație. În orașele mai mici timpul alocat fiecărei cununii este ceva
        mai mare, astfel încât oamenii au timp să servească și prăjituri și
        șampanie. Indiferent unde și când veți alege să faceți cununia civilă,
        nu uitați că voi doi sunteți cei mai importanți, bucurați-vă de moment
        și faceți cât mai multe poze.
      </p>

      <div className="nav-section">
        <Link to="/invitatiile">◀  Cum alegem invitațiile de nuntă? </Link>
        <Link to="/buchetul">▶ Buchetul de mireasă</Link>
      </div>
    </div>
  </Layout>
)

export default CununiaCivilaPage
